import React, { useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";

const ChatWindow = ({ messages, loading }) => {
  const chatEndRef = useRef(null); // Reference to the end of the chat list

  // Auto-scroll to the bottom when messages change
  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="chat-content" id="style-7">
      <ul className="chat-list" id="chat-list">
        {messages.map((msg, index) => (
          <li className={msg.type} key={index}>
            <p id="message">
              {msg.content === "" ? (
                <div className="dot-container">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              ) : (
                <ReactMarkdown>{msg.content}</ReactMarkdown>
              )}
            </p>
          </li>
        ))}
        {/* Dummy div to scroll to */}
        <div ref={chatEndRef} />
      </ul>
    </div>
  );
};

export default ChatWindow;
