import React, { useState } from "react";
import logo from "../img/ai.svg";

const ChatInput = ({ onSubmit, isResponseComplete }) => {
  const [userInput, setUserInput] = useState("");
  const [loading, setLoading] = useState(false);
  const maxCharacters = 200;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading || !userInput.trim()) return;
    setLoading(true);
    onSubmit(userInput);
    setUserInput("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !loading && userInput.trim()) {
      handleSubmit(e);
    }
  };

  // This effect listens for when the response is complete
  React.useEffect(() => {
    if (isResponseComplete) {
      setLoading(false);
    }
  }, [isResponseComplete]);

  return (
    <div className="form-content">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="form position-relative rounded-pill">
              <input
                type="text"
                className="form-control-lg rounded-pill w-100 border-0 py-3"
                placeholder="Sana nasıl yardımcı olabilirim?"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyDown={handleKeyPress}
                disabled={loading}
                maxLength={maxCharacters}
              />
              <button
                className="btn btn-search"
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
              >
                <img src={logo} alt="ai icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatInput;
